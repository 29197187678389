import Vue from "vue";
import Router from "vue-router";
import axios from "axios";
import store from "./store";

Vue.use(Router);
let router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      props: true,
      component: () => import("./layout/Default"),
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: "",
          name: "dashboard",
          meta: {
            requiresAuth: true,
          },
          component: () => import("./views/Dashboard/Dashboard"),
        },
        {
          path: "/add",
          name: "adds",
          meta: {
            requiresAuth: true,
          },
          component: () => import("./layout/Admins/addMenu"),
        },
        {
          path: "/CategoryList",
          name: "CategoryList",
          meta: {
            requiresAuth: true,
          },
          component: () => import("./views/Category/category"),
        },
        {
          path: "/TitleList",
          name: "TitleList",
          meta: {
            requiresAuth: true,
          },
          component: () => import("./views/Title/title"),
        },
        {
          path: "/volunteer",
          name: "volunteer",
          meta: {
            requiresAuth: true,
          },
          component: () => import("./views/volunteerTitle/title"),
        },
        {
          path: "/Applications",
          name: "Applications",
          meta: {
            requiresAuth: true,
          },
          component: () => import("./views/Applications/applications"),
        },
        {
          path: "/volunteerApplication",
          name: "volunteerApplication",
          meta: {
            requiresAuth: true,
          },
          component: () => import("./views/volunteerApplication/applications"),
        },
        {
          path: "/viewApplication",
          name: "viewApplication",
          meta: {
            requiresAuth: true,
          },
          component: () => import("./views/Applications/viewApplication"),
        },
        {
          path: "/viewVolunteerApplication",
          name: "viewVolunteerApplication",
          meta: {
            requiresAuth: true,
          },
          component: () => import("./views/volunteerApplication/viewApplication"),
        },
        //Employee document
        {
          path: "/employeedocument",
          name: "employeedocument",
          meta: {
            requiresAuth: true,
          },
          component: () => import("./views/EmployeeDocument/documents"),
        },
        {
          path: "/viewdocument",
          name: "viewdocument",
          meta: {
            requiresAuth: true,
          },
          component: () => import("./views/EmployeeDocument/viewDoc"),
        },
        {
          path: "/employeelist",
          name: "employeelist",
          meta: {
            requiresAuth: true,
          },
          component: () => import("./views/EmployeeList/list"),
        },
        {
          path: "/employeeview",
          name: "employeeview",
          meta: {
            requiresAuth: true,
          },
          component: () => import("./views/EmployeeList/listView"),
        },

        
        {
          path: "/ProductType",
          name: "ProductType",
          meta: {
            requiresAuth: true,
          },
          component: () => import("./views/Products/ProductType/productType"),
        },
        {
          path: "/addProducts",
          name: "addProducts",
          component: () => import("./views/Products/addProduct"),
        },
        {
          path: "/products",
          name: "Products",
          component: () => import("./views/Products/products"),
        },
        {
          path: "/product/:id",
          name: "Product",
          component: () => import("./views/Products/product"),
          props: true,
        },
        {
          path: "/editProduct/:id",
          name: "EditProduct",
          component: () => import("./views/Products/editProduct"),
          props: true,
        },
        {
          path: "/aboutUs",
          name: "aboutUs",
          component: () => import("./views/About/about"),
        },
        {
          path: "/Video",
          name: "Video",
          component: () => import("./views/About/video"),
        },
        {
          path: "/Announcements",
          name: "Announcements",
          component: () => import("./views/About/announcements"),
        },
        {
          path: "/purchaseReports",
          name: "Purchases",
          component: () =>
            import("./views/Reports/Products/purchase"),
        },
        
        {
          path: "/quizDetails",
          name: "quizDetails",
          meta: {
            requiresAuth: true,
          },
          component: () => import("./views/Contest/quizApplications"),
        },
        {
          path: "/viewQuiz",
          name: "viewQuiz",
          meta: {
            requiresAuth: true,
          },
          component: () => import("./views/Contest/viewQuiz"),
        },
        {
          path: "/Subscriptions",
          name: "Subscriptions",
          meta: {
            requiresAuth: true,
          },
          component: () => import("./views/Contest/subscriptions"),
        },


        // NEW Mod 
        
        {
          path: "/Roles",
          name: "Roles",
          meta: {
            requiresAuth: true,
          },
          component: () => import("./views/RolesandDepartments/roles"),
        },
        {
          path: "/Departments",
          name: "Departments",
          meta: {
            requiresAuth: true,
          },
          component: () => import("./views/RolesandDepartments/departments"),
        },
        {
          path: "/Projects",
          name: "Projects",
          meta: {
            requiresAuth: true,
          },
          component: () => import("./views/Projects/projects"),
        },
        {
          path: "/Positions",
          name: "Positions",
          meta: {
            requiresAuth: true,
          },
          component: () => import("./views/RolesandDepartments/positions"),
        },


      ],
    },
    {
      path: "/",
      props: true,
      component: () => import("./layout/Authentication"),
      meta: {
        requiresAuth: false,
      },
      children: [
       
        {
          path: "login",
          name: "login",
          meta: {
            requiresAuth: false,
          },
          component: () => import("./views/Authentication/Login"),
        },
        {
          path: "signup",
          name: "signup",
          meta: {
            requiresAuth: false,
          },
          component: () => import("./views/Authentication/Signup"),
        },
      ],
    },

    {
      path: "/servererror",
      name: "servererror",
      props: true,
      component: () => import("./layout/500"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "*",
      name: "404pagenotfound",
      props: true,
      component: () => import("./layout/404"),
      meta: {
        requiresAuth: false,
      },
    },
  ],
  scrollBehavior() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  },
});
router.beforeEach((to, from, next) => {
   //new lines
   localStorage.setItem("PRE", from.path);

  // end  of newely added lines
  if (
    to.matched.some((route) => route.meta.requiresAuth == true) &&
    store.state.isLoggedIn == false
  ) {
    console.log(from);
    next({ name: "login", params: { lastPage: from } });
    return;
  }
    if (store.state.isLoggedIn == true) {
      axios({
        method: "POST",
        url: "job/user/me",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (!response.data.status) {
            store.commit("sessionOut", true);
            return;
          }
        })
        .catch((err) => {
          var msg = err;
          console.log(msg);
          store.commit("sessionOut", true);
        });
    }
  if (
    (to.name == "login" || to.name == "home") &&
    store.state.isLoggedIn == true
  ) {
    next({ name: "dashboard" });
    return;
  }
  next();
});

export default router;
