<template>
  <div>
    <vue-element-loading
      :active="$store.state.appLoading"
      :is-full-screen="!!!contain"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    >
    </vue-element-loading>
  </div>
</template>
<script>
export default {
  props: ["contain"],
};
</script>