<template>
  <div>
    <PageLoader />
    <v-app id="app">
      <span style="min-height: 100vh">
        <router-view  v-bind:storage="sideNav" />
      </span>
    </v-app>
  </div>
</template>
<script>
import axios from "axios";

import "./assets/styles/fonts.css";
import "./assets/styles/appStyle.css";

export default {
  data() {
    return {
      sideNav: false,
    };
  },
  beforeMount() {
    if (typeof localStorage.getItem("token") == "string") {
      this.$store.commit("appLoading", true);
      axios({
        method: "POST",
        url: "job/user/me",
        headers: {
          "x-auth-token": localStorage.getItem ("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.$store.commit("appLoading", false);
            this.$store.commit("userData", response.data.data);
            this.$store.commit("userType", response.data.data.usertype);
            // this.$store.commit("menu", response.data.menu);
          }
          else
          {
            this.$store.commit("appLoading", false);

          }
        })
        .catch((err) => {
          this.$store.commit("appLoading", false);
          console.log(err);
        });
    }
  },
  methods: {},
};
</script>
