import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router';
import axios from 'axios'
import store from './store'
import VueElementLoading from 'vue-element-loading'
import ServerError from './layout/500'
import PageLoader from './components/PageLoader'
import ImageLoader from './components/ImageLoader'
import OtpInput from "@bachdgvn/vue-otp-input";


import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);
// import VueSocialSharing from 'vue-social-sharing'

// Vue.use(VueSocialSharing);
Vue.component("v-otp-input", OtpInput);
import 'viewerjs/dist/viewer.css'
import viewer from 'v-viewer'
Vue.use(viewer)
import { VueEditor } from "vue2-editor";
Vue.component('VueEditor', VueEditor)
Vue.component('VueElementLoading', VueElementLoading)
Vue.component('ServerError', ServerError)
Vue.component('PageLoader', PageLoader)
Vue.component('ImageLoader', ImageLoader)

Vue.config.productionTip = false

axios.defaults.baseURL = 'https://wtiadmin.in';
Vue.prototype.baseURL = "https://wtiadmin.in"
Vue.prototype.mediaURLOld = "https://wtiadmin.in/i/"
Vue.prototype.mediaURL = "https://wtiadmin.in/i/"






// axios.defaults.baseURL = 'http://192.168.55.75:5000';
// Vue.prototype.baseURL = "http://192.168.55.75:5000"
// Vue.prototype.mediaURLOld = "http://192.168.55.111:5050/i/"
// Vue.prototype.mediaURL = "http://192.168.55.111:5050/i/"

Vue.prototype.appColor = '#005f32'


new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
